@media only screen and (max-width: 767px) {
  .typography {
    font-weight: 400;
  }

  .typography[data-variant="h1"] {
    font-size: var(--font-size, 1.688rem);
    line-height: var(--line-height, 2.188rem);
  }

  .typography[data-variant="h2"] {
    font-size: var(--font-size, 1.5rem);
    line-height: var(--line-height, 2rem);
  }

  .typography[data-variant="h3"] {
    font-size: var(--font-size, 1.375rem);
    line-height: var(--line-height, 1.625rem);
  }

  .typography[data-variant="h4"] {
    font-size: var(--font-size, 1.25rem);
    line-height: var(--line-height, 1.5rem);
  }

  .typography[data-variant="h5"] {
    font-size: var(--font-size, 1.125rem);
    line-height: var(--line-height, 1.375rem);
  }

  .typography[data-variant="p1"] {
    font-size: var(--font-size, 1rem);
    line-height: var(--line-height, 1.5rem);
  }

  .typography[data-variant="p2"] {
    font-size: var(--font-size, 1rem);
    line-height: var(--line-height, 1.5rem);
  }

  .typography[data-variant="p3"] {
    font-size: var(--font-size, 0.938rem);
    line-height: var(--line-height, 1.375rem);
  }

  .typography[data-color="main"] {
    color: var(--color-text, var(--color-text-main));
  }

  .typography[data-color="subtitle"] {
    color: var(--color-text, var(--color-text-subtitle));
  }

  .typography[data-color="description"] {
    color: var(--color-text, var(--color-text-description));
  }

  .typography[data-color="accent"] {
    color: var(--color-text, var(--color-text-accent));
  }
}
@media only screen and (min-width: 768px) {
  .typography {
    font-weight: 400;
  }

  .typography[data-variant="h1"] {
    font-size: var(--font-size, 3.25rem);
    line-height: var(--line-height, 3.875rem);
  }

  .typography[data-variant="h2"] {
    font-size: var(--font-size, 2.375rem);
    line-height: var(--line-height, 2.875rem);
  }

  .typography[data-variant="h3"] {
    font-size: var(--font-size, 1.75rem);
    line-height: var(--line-height, 2rem);
  }

  .typography[data-variant="h4"] {
    font-size: var(--font-size, 1.5rem);
    line-height: var(--line-height, 1.875rem);
  }

  .typography[data-variant="h5"] {
    font-size: var(--font-size, 1.25rem);
    line-height: var(--line-height, 1.563rem);
  }

  .typography[data-variant="p1"] {
    font-size: var(--font-size, 1.125rem);
    line-height: var(--line-height, 1.625rem);
  }

  .typography[data-variant="p2"] {
    font-size: var(--font-size, 1rem);
    line-height: var(--line-height, 1.5rem);
  }

  .typography[data-variant="p3"] {
    font-size: var(--font-size, 1.5rem);
    line-height: var(--line-height, 2rem);

    font-size: 0.938rem;
    line-height: 1.375rem;
  }

  .typography[data-color="main"] {
    color: var(--color-text, var(--color-text-main));
  }

  .typography[data-color="subtitle"] {
    color: var(--color-text, var(--color-text-subtitle));
  }

  .typography[data-color="description"] {
    color: var(--color-text, var(--color-text-description));
  }

  .typography[data-color="accent"] {
    color: var(--color-text, var(--color-text-accent));
  }
}
